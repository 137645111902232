/* Resetting Body */
body {
    margin: 0;
    padding: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    scroll-behavior: smooth;
}

h1, h2, h3 {
  margin: 0 auto;
}


/* Hero Container */
.hero-container {
    font-family: 'Plus Jakarta Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding-top: 10vh; /* Added padding to account for the header */
    background-size: cover;
    background-position: center;
    position: relative;
    scroll-behavior: smooth;
}

/* Hero Section Positioned at the Top */
.hero-title-container {
    text-align: center;
    color: #ffffff;
    position: relative; /* Changed from absolute */
    margin-top: 5vh; /* Default margin for larger screens */
    max-width: 80%; /* Limit the width for better readability */
    z-index: 1;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
    .hero-title-container {
        margin-top: 2vh; /* Reduced margin-top for mobile screens */
        margin-bottom: 1vh; /* Reduced margin-top for mobile screens */
    }
}

/* Media query for very small screens */
@media (max-width: 480px) {
    .hero-title-container {
        margin-top: 3vh; /* Further reduced for very small screens */
    }
}

/* Hero Title */
.hero-title {
    font-size: 2.5rem; /* Adjusted for better scaling */
    font-weight: 600;
    letter-spacing: 0.3rem;
    background: -webkit-linear-gradient(black, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px; /* Added margin to separate from the sub-title */
}

/* Media query for tablets */
@media (max-width: 768px) {
    .hero-title {
        font-size: 2.2rem; /* Slightly smaller for tablets */
        letter-spacing: 0.25rem; /* Adjust letter spacing */
        margin-bottom: 12px; /* Reduce margin */
    }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
    .hero-title {
        font-size: 2rem; /* Smaller font size for mobile */
        letter-spacing: 0.2rem; /* Adjust letter spacing */
        margin-bottom: 10px; /* Reduce margin for tighter spacing */
        color: red;
    }
}

/* Sub-title Styling */
.hero-sub-title {
    font-size: 1.2rem;
    color: #777; /* Softer color */
    margin-bottom: 20px;
}

/* Media query for tablets */
@media (max-width: 768px) {
    .hero-sub-title {
        font-size: 1.1rem; /* Adjust font size for tablets */
        margin-bottom: 18px; /* Adjust margin */
    }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
    .hero-sub-title {
        font-size: 1rem; /* Smaller font size for mobile */
        margin-bottom: 2vh; /* Adjust margin for better spacing */
    }
}

/* Sci-fi box styling */
.sci-fi-box {
    width: clamp(230px, 15vw, 650px);
    margin-top: 10vh; /* Base margin for standard screens */
    background-color: #324056;
    border-radius: 55px;
    padding: clamp(15px, 2vw, 30px);
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.6), 0px 0px 25px rgba(70, 153, 180, 0.6);
    position: relative;
    z-index: 1;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* Media query for larger monitors */
@media (min-width: 1600px) {
    .sci-fi-box {
        margin-top: 20vh; /* Increase margin-top for larger screens */
    }
}

/* Media query for very large monitors */
@media (min-width: 2000px) {
    .sci-fi-box {
        margin-top: 30vh; /* Adjust positioning for extra-large screens */
    }
}

/* Media query for tablets */
@media (max-width: 768px) {
    .sci-fi-box {
        width: clamp(200px, 40vw, 400px); /* Adjust width for smaller screens */
        margin-top: 5vh; /* Position the sci-fi box higher */
    }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
    .sci-fi-box {
        width: clamp(180px, 50vw, 300px); /* Further reduce width for smaller screens */
        margin-top: 3vh; /* Position the sci-fi box even higher on mobile */
    }

}

/* Remove background blur */
.sci-fi-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* Remove backdrop-filter for no blur */
}

/* Transition to a lighter shade of blue on hover */
.sci-fi-box:hover {
    background-color: #3A5068; /* Lighter shade of blue */
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.6), 
                0px 0px 35px rgba(70, 130, 180, 0.8),
                0px 0px 60px rgba(42, 228, 245, 0.5);
    transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
    cursor: pointer;
}

.sci-fi-box {
    /* Existing styles */
    transition: all 2s ease-in-out; /* Slow animation for expansion */
}

.sci-fi-box:hover .shine-text {
    color: white; /* Change the text color to white */

}

.sci-fi-box:hover .cta-button {
    transform: translateY(-3px); /* Elevate the button slightly */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2), 0 0 10px rgba(74, 140, 242, 0.5); /* Add box shadow */
}

.sci-fi-box {
    transition: width 0.8s ease-in-out, height 0.8s ease-in-out; /* Smooth transition for dimensions */
}

.sci-fi-box.expanded {
    width: 400px; /* Default width */
    height: 330px; /* Default height */
    border-radius: 20px;
    padding: 25px; /* Add padding for more spacing */
    background-color: #3A5068; /* Lighter shade of blue */
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.6), 
                0px 0px 35px rgba(70, 130, 180, 0.8),
                0px 0px 60px rgba(42, 228, 245, 0.5);
    transition: background-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
    .sci-fi-box.expanded {
        width: 70%; /* Set width as a percentage to adapt to screen size */
        height: auto; /* Adjust height to maintain proportion */
        padding: 20px; /* Reduce padding for better fit */
        border-radius: 15px; /* Slightly smaller border radius */
    }
}

/* Media query for very small screens (e.g., iPhone) */
@media (max-width: 480px) {
    .sci-fi-box.expanded {
        width: 80%; /* Further reduce width for smaller screens */
        height: auto; /* Adjust height */
        padding: 15px; /* Reduce padding */
        border-radius: 10px; /* Smaller border radius */
        margin-top: 0px;
    }
}

/* Logo Styling */
.logo-image {
    width: 200px;
    height: auto;
    margin-bottom: 10px;
    padding: 0;
}

/* Updated Text Styling for "MIRIEL" */
.logo-name {
    font-size: 2.2rem; /* Larger font size */
    color: #e0e0e0;
    margin-bottom: 20px;
    letter-spacing: 0.6rem; /* Exaggerated letter spacing */
    text-transform: uppercase; /* Ensure all caps */
    font-weight: 600; /* Emphasize the boldness */
    margin-left: 5px;
}

/* Call to action container */
.cta-container {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 40px;
}

.cta-button {
    display: inline-block;
    color: #ffffff; /* White text */
    text-decoration: none;
    font-size: 17.5px;
    font-weight: bold;
    background-color: #2b7dda; /* Dark blue */
    padding: 10px 20px; /* Maintain padding */
    border-radius: 20px;
    transition: all 0.3s ease-in-out; /* For elevation and shadow */
    margin-top: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2), 0 0 10px rgba(74, 140, 242, 0.5);
    max-width: fit-content; /* Ensure button width fits its content */
    border: 0;
}

/* Shine text animation */
.shine-text {
    display: inline-block;
    background: #ffffff;
    color: white;
    -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
    -webkit-mask-size: 200%;
    mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
    mask-size: 200%;
    animation: shine 2.5s infinite;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
 /* Soft glow */
    transition: all 0.3s ease-in-out; /* Smooth transition for color and shadow */
}

@keyframes shine {
    from {
        -webkit-mask-position: 150%;
        mask-position: 150%;
    }
    to {
        -webkit-mask-position: -50%;
        mask-position: -50%;
 
 
    }
}




.submit-waitlist-form-button {
    display: inline-block;
    color: #ffffff; /* White text */
    text-decoration: none;
    font-size: 17.5px;
    font-weight: bold;
    background-color: #2b7dda; /* Dark blue */
    padding: 10px 20px; /* Maintain padding */
    border-radius: 20px;
    transition: all 0.3s ease-in-out; /* For elevation and shadow */
    margin-top: 5px;
    margin-bottom: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2), 0 0 10px rgba(74, 140, 242, 0.5);
    max-width: fit-content; /* Ensure button width fits its content */
    border: 0;

}

.confirm-button {
    display: inline-block;
    color: #ffffff; /* White text */
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #2b7dda; /* Dark blue */
    padding: 12px 25px; /* Maintain padding */
    border-radius: 20px;
    transition: all 0.3s ease-in-out; /* For elevation and shadow */
    margin-top: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2), 0 0 10px rgba(74, 140, 242, 0.5);
    max-width: fit-content; /* Ensure button width fits its content */
    border: 0;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
    color: #ffffff;
    margin-top: 0;
}

.form-instructions {
    margin-bottom: 15px;
    margin-top: 0;
    font-size: 20px; /* Slightly larger font size */
    font-weight: 600; /* Make it bold */
    color: #ffffff; /* Brighter white color */
}

/* For secondary lines */
.form-instructions.secondary {
    font-size: 17px; /* Smaller font size for secondary text */
    font-weight: 500; /* Slightly less bold */
    color: #dcdcdc; /* Slightly dimmer color */
    margin-bottom: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .form-instructions {
    margin-bottom: 15px;
    margin-top: 0;
    font-size: 1.1rem; /* Slightly larger font size */
    font-weight: 600; /* Make it bold */
    color: #ffffff; /* Brighter white color */
  }

  /* For secondary lines */
  .form-instructions.secondary {
    font-size: .9rem; /* Smaller font size for secondary text */
    font-weight: 500; /* Slightly less bold */
    color: #dcdcdc; /* Slightly dimmer color */
    margin-bottom: 20px;
  }
}

.form-input {
    margin: 10px 10px; /* Increased margin for more spacing */
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 90%;
    background-color: #2f3134;
    color: #ffffff;
    font-size: 16px; /* Ensure the font size is at least 16px */
}

.linkedin .discord {
    color: #0073e6;
}

.linkedin {
    margin-right: 5px;
}

.thank-you-message {
    font-size: 1.2rem; /* Larger text size */
    color: #ffffff; /* White text color */
    text-align: center; /* Centered text alignment */
    margin-top: 0px; /* Space at the top */
    font-weight: 500;
}

.thank-you-message p {
    margin-bottom: 20px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-bottom: 0px;
}

.close-icon:hover {
  color: #ff4d4d; /* Optional hover color */
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center contents vertically */
    height: 100%; /* Ensure it takes up the full height of the sci-fi box */
}

.loading-text {
    font-size: 1.3rem;
    color: white;
}

.loading-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.loading-dots div {
    width: 20px;
    height: 20px;
    background-color: #0073e6;
    border-radius: 50%;
    animation: loadingDots 0.6s infinite alternate;
}

.loading-dots div:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots div:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loadingDots {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.5);
        opacity: 0.5;
    }
}

.highlight-text {
    font-size: 1.4rem; /* Slightly larger for emphasis */
    font-weight: bold;
    color: #ffffff; /* White */
}

.subtext {
    font-size: 1.2rem; /* Smaller size */
    font-weight: 600; /* Semi-bold */
    color: #c0c0c0; /* Lighter gray */
}

.normal-text {
    font-size: 1rem;
    font-weight: 400;
    color: #e0e0e0;
    margin-top: 0px;
}

.link-container {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between the buttons */
    margin-top: 30px;
    margin-bottom: 20px;
}

.linkedin,
.discord {
    font-size: 1rem;
    color: #ffffff; /* White text */
    transition: color 0.3s;
    text-decoration: none;
}

.linkedin:hover,
.discord:hover {
    color: #0073e6; /* Light blue on hover */
}

.link-separator {
    display: inline-block;
    width: 0px; /* Horizontal space */
}

/* Adjust layout for mobile screens */
@media (max-width: 768px) {
    .close-icon {
        font-size: 1rem;
        color: #d65959;
    }
}




/* Background continuation */
/* Content Wrapper */
.content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background: linear-gradient(to right, #201f22, #2f3134, #201f22);
}

.content-sections {
    width: 100%; /* Take full width of the wrapper */
    padding: 0; /* Remove padding for better alignment */
    color: #333; /* Change text color for better readability */
}

/* Section Styling */
.section {
    margin-bottom: 120px;
    text-align: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.section {
    opacity: 0;
    transform: translateY(50px); /* Initial state is slightly below the viewport */
    transition: opacity 0.6s ease, transform 0.6s ease; /* Smooth fade-in and slide-up */
}

.section.visible {
    opacity: 1;
    transform: translateY(0); /* Move into place */
}

/* Wrapper specifically for fade-in effect */
.fade-in-wrapper {
    opacity: 0;
    transform: translateY(50px); /* Initial state is slightly below the viewport */
    transition: opacity 0.6s ease, transform 0.6s ease; /* Smooth fade-in and slide-up */
}

.fade-in-wrapper.visible {
    opacity: 1;
    transform: translateY(0); /* Move into place */
}

.learn-more {
    display: inline-block;
    margin-top: 20px;
    font-size: 1rem;
    color: #4a8cf2;
    text-decoration: none;
}

.learn-more:hover {
    text-decoration: underline;
}

/* Increased space between Hero and First Section */
.content-sections {
    padding-top: 40px;
}

.body-header-container {
  text-align: center; /* Centers all content inside this container */
  margin-bottom: 30px;
}

.body-header {
  margin-bottom: 2cap;
  font-size: 2.5rem;
  max-width: 795px;
  color: #cfcfcf;
  text-align: center; /* Ensure the text itself is centered */
  margin: 0 auto; /* Center the block horizontally */
}


@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500&display=swap');


/* Wrapper for all sections */
.ai-integration-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.ai-section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 40px;
}

.alternate-layout {
  grid-template-columns: 1fr 2fr;
}

.ai-section-number-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.ai-section-number {
  font-size: 3rem;
  font-weight: bold;
  color: #83b8ed;
}

.ai-section-header {
  font-size: 2.5rem;
  font-weight: bold;
  color: #cfcfcf;
  margin-left: 0px;
}

.ai-code-block {
  background-color: #324056;
  color: #cdd9e5;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ai-code-line-01 {
  margin-bottom: 15px;
  text-align: center; /* Center align text */
  max-width: 450px; /* Restrict width */
  margin: 0 auto; /* Center the container */
}
.ai-code-line-02 {
  margin-bottom: 15px;
  text-align: center; /* Center align text */
  max-width: 625px; /* Restrict width */
  margin: 0 auto; /* Center the container */
}
.ai-code-line-03 {
  margin-bottom: 15px;
  text-align: center; /* Center align text */
  margin: 0 auto; /* Center the container */
}
.ai-code-line-04 {
  margin-bottom: 15px;
  text-align: center; /* Center align text */
  margin: 0 auto; /* Center the container */
}

.ai-code-comment {
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600;
}

.ai-code-keyword {
  color: #569cd6;
  font-weight: bold;
}

.ai-code-list li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  font-size: 1.2rem;
}

.ai-code-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #569cd6;
}

/* Responsive styling */
@media (max-width: 768px) {
  .ai-section {
    display: flex; /* Change from grid to flex */
    flex-direction: column; /* Stack elements vertically */
    gap: 20px;
  }

  .ai-section-number-header {
    align-items: center; /* Center-align for smaller screens */
  }

  .ai-section-number {
    font-size: 2rem; /* Adjust font size for better readability */
    text-align: center; /* Center the number */
  }

  .ai-section-header {
    font-size: 1.8rem; /* Adjust the header size */
    text-align: center; /* Center the header text */
    max-width: 250px;
  }
  .ai-section-header-04 {
    font-size: 1.8rem; /* Adjust the header size */
    text-align: center; /* Center the header text */
    max-width: 270px;
    color: #cfcfcf;
  }

  .ai-code-block {
    width: 100%; /* Ensure the code block spans full width */
    text-align: center;
    padding: 15px;
  }

  .ai-code-line {
    font-size: 1.4rem; /* Scale down text size */
  }

  .ai-code-list li {
    font-size: 1rem; /* Adjust list font size */
    padding-left: 15px; /* Reduce padding for better fit */
    text-align: left;
  }

  .ai-code-list li::before {
    font-size: 1rem; /* Scale down bullet size */
  }

  .ai-code-comment {
    font-size: 1.4rem;
  }

  .body-header {
    font-size: 2rem;
  }
}
