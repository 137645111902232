.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background: linear-gradient(to right, #201f22, #2f3134, #141418);
    color: #fff;
}

.heading {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #fff;
}

.grid {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 30px;
}

.card {
    padding: 1.5rem;
    border-radius: 15px;
    text-align: center;
    width: 300px;
}

.image {
    border-radius: 15px;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.nameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.name {
    font-size: 1.5rem; /* Larger font size */
    font-weight: bold;
    color: #f1f1f1;
}

.coFounder {
    font-size: 1.1rem; /* Larger co-founder text */
    color: #b0b0b0; /* A lighter shade of grey */
    margin-top: 0.3rem;
}

.linkedin {
    color: #0e76a8;
    font-size: 1.5rem;
    transition: color 0.3s;
    margin-top: 0px;
    padding-top: 0px;
}

.linkedin:hover {
    color: #085494; /* Darker shade on hover */
}












/* General styling for page layout */
body, html {
    margin: 0;
    padding: 0;
    min-height: 100%; /* Ensure the background spans the entire height */
    background: linear-gradient(to right, #201f22, #2f3134, #141418);
    font-family: 'Plus Jakarta Sans', sans-serif;
}

/* Home container */
.home-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align content from the top */
    flex-direction: column;
    min-height: 100vh; /* Ensure it spans the full viewport height */
    width: 100%; /* Ensure it spans the full width */
    background: linear-gradient(to right, #201f22, #2f3134, #141418);
    background-size: cover;
    background-position: center;
    padding-bottom: 50px; /* Space for additional content */
}

/* Hero section styling */
.build-different-container {
    text-align: center;
    color: #ffffff;
    position: relative;
    margin-top: 15vh; /* Ensure the container moves down with content */
    width: 100%; /* Allow it to adjust based on content width */
}

/* Prevents container from moving up */
.build-different-title {
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 0.3rem;
    margin-bottom: 20px;
}


.home-description2 {
    font-size: 1.3rem;
    color: #999;
    margin-bottom: 30px;
}

.team-description {
    text-align: center;
    color: #cccccc; /* Light gray for subtle contrast */
    font-size: 1rem; /* Smaller text */
    margin-top: 0px; /* Add some spacing */
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5; /* Improved readability */
    max-width: 1100px;
}

/* Ensure full-page background */
.container {
    background: linear-gradient(to right, #201f22, #2f3134, #141418);
    min-height: 100vh; /* Makes sure it spans the full length */
    padding: 20px; /* Add some padding for spacing */
    box-sizing: border-box; /* Include padding in the element's total width and height */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .grid {
        flex-direction: column; /* Stack co-founder cards vertically */
        gap: 1.5rem; /* Reduce gap between cards */
        width: 100%; /* Ensure full-width for smaller screens */
    }

    .team-description {
        padding: 15px; /* Add more padding for better readability */
        font-size: 0.9rem; /* Adjust font size for mobile */
    }

    .card {
        width: 80%; /* Adjust width for better fit on mobile screens */
        margin: 0 auto; /* Center cards */
    }

    .container {
        padding: 1rem; /* Reduce padding for better mobile fit */
    }

    .build-different-title {
        font-size: 2rem; /* Smaller font size for mobile */
    }
}

@media (max-width: 480px) {
    .team-description {
        padding: 20px; /* Further reduce padding */
        font-size: 0.85rem; /* Adjust font size for smaller mobile screens */
    }

    .build-different-title {
        font-size: 1.8rem; /* Reduce font size more for very small screens */
    }

    .card {
        width: 80%; /* Full width on extra small screens */
    }
}

.toggle-button {
    background-color: transparent;
    border: none;
    color: #0073e6;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    padding: 5px;
}

.toggle-button:hover {
    text-decoration: underline;
}
