.footer {
    /* background: linear-gradient(to right, #201f22, #2f3134, #141418); */
    background-color: white;
    padding: 0px;
    color: #e0e0e0; /* Light gray text for readability */
    display: flex;
    justify-content: center;
    border-top: 1px solid #4a4a4a;
}

.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    width: 100%;
    max-width: 1200px;
}

.brandSection {
    text-align: center;
    margin-bottom: 0px;
}

.logoAndName {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between logo and brand name */
    margin-bottom: 0px; /* Space below logo and brand name */
}

.logo {
    width: 80px; /* Adjust the size as needed */
    height: auto;
}

.brandName {
    font-size: 1.5rem;
    font-weight: bold;
    color: #676767;
}

.copyright {
    font-size: 1rem;
    color: #aeaeae; /* Slightly lighter gray */
    margin-left: 20px;
}

footer {
    margin-top: auto;
    width: 100%;
}












.footerLinks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.footerLinks div {
    flex: 1;
    min-width: 150px;
}

h4 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #ffffff;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin-bottom: 5px;
    color: #b0b0b0; /* Lighter gray for link text */
}

.socialIcons {
    display: flex;
    gap: 10px;
}

.socialIcons svg {
    font-size: 1.5rem;
    color: #ffffff; /* White color for icons */
    cursor: pointer;
    transition: color 0.3s;
}

.socialIcons svg:hover {
    color: #0073e6; /* Blue color on hover */
}
