body {
    scroll-behavior: smooth;

}
/* General header styles */
.header-container {
    font-family: 'Plus Jakarta Sans', sans-serif;
}
  
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: transparent;
    width: 100%;
    position: fixed; /* Sticks the header to the top */
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s ease; /* Smooth transition for background change */
}

/* Style for when the header is scrolled */
.scrolled {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for depth */
}


/* Container that holds the entire header */
.header-container {
    width: 100%;
    margin: 0 auto; /* Centers the header */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px; /* Add some padding for responsiveness */
}

/* Container for logo and text */
.logoAndText {
    display: flex;
    align-items: center;
}

/* Logo styles */
.logo {
    height: 65px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: auto;
}

/* Brand name (MIRIEL) styles */
.brandName {
    font-size: 1.8rem;
    font-weight: 550;
    color: black;
    margin-top: 0px;
    letter-spacing: 0.3rem; /* Exaggerated letter spacing */
}

/* Container for navigation links and CTA button */
.rightLinks {
    display: flex;
    align-items: center;
    gap: 30px;
}

/* Navigation links styles */
.navLinks {
    display: flex;
    gap: 20px;
    margin-left: 40px;
}

.link {
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    margin-right: 20px;
    margin-top: 0px;
}

/* CTA button styles */
.ctaButton {
    text-decoration: none;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s;
    margin-right: 10px;
    margin-top: 0px;
}

.ctaButton:hover {
    background-color: #0056b3;
}

.cta-button {
    display: inline-block;
    color: white; /* White text */
    text-decoration: none;
    font-size: 17.5px;
    font-weight: bold;
    background-color: #2b7dda; /* Dark blue */
    padding: 10px 20px; /* Maintain padding */
    border-radius: 20px;
    transition: all 0.3s ease-in-out; /* For elevation and shadow */
    margin-right: 10px;
    margin-top: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2), 0 0 10px rgba(74, 140, 242, 0.5);
    border: 0px;
}
.cta-button2 {
    display: inline-block;
    color: #2b7dda; /* White text */
    text-decoration: none;
    font-size: 17.5px;
    font-weight: bold;
    background-color: white; /* Dark blue */
    padding: 10px 20px; /* Maintain padding */
    border-radius: 20px;
    transition: all 0.3s ease-in-out; /* For elevation and shadow */
    margin-right: 0px;
    margin-top: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2), 0 0 10px rgba(74, 140, 242, 0.5);
}
.cta-button3 {
    display: inline-block;
    color: black; /* White text */
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: #2b7dda00; /* Dark blue */
    padding: 10px 20px; /* Maintain padding */
    border-radius: 10px;
    transition: all 0.3s ease-in-out; /* For elevation and shadow */
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    max-width: fit-content; /* Ensure button width fits its content */
}

/* Shine text animation */
.shine-text {
    display: inline-block;
    background: #ffffff;
    color: white;
    -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
    -webkit-mask-size: 200%;
    mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
    mask-size: 200%;
    animation: shine 2.5s infinite;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
 /* Soft glow */
    transition: all 0.3s ease-in-out; /* Smooth transition for color and shadow */
}

@keyframes shine {
    from {
        -webkit-mask-position: 150%;
        mask-position: 150%;
    }
    to {
        -webkit-mask-position: -50%;
        mask-position: -50%;
 
 
    }
}



.connectLink {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.1rem;
}

.connectLink:hover .dropdownMenu,
.dropdownMenu:hover {
    display: flex;
}

.connectLink:hover .arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.dropdownArrow {
    transition: transform 0.3s ease;
}
.dropdownText {
    margin-left: 20px;
}

.dropdownMenu {
    display: none;
    position: absolute;
    top: 100%; /* Ensure proper alignment with the link */
    left: 50%; /* Centering */
    transform: translateX(-50%); /* Center alignment */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 0px;
    z-index: 1001;
    flex-direction: column;
    gap: 0px;
    width: max-content; /* Adjust width for better appearance */
}

.dropdownItem {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 20px;
    font-size: 1rem; /* Increase font size for readability */
    transition: background-color 0.2s;
}

.dropdownItem i {
    margin-right: 10px;
    font-size: 1.5rem; /* Increase icon size */
}

.dropdownItem:hover {
    background-color: #f8f8f8;
    border-radius: 5px;
}



/* Icons styling */
.icon-contact::before {
    content: "📞";
    margin-right: 8px;
}

.icon-linkedin::before {
    content: "🔗";
    margin-right: 8px;
}

.icon-discord::before {
    content: "💬";
    margin-right: 8px;
}


.connectLink {
    display: flex;
    align-items: center; /* Centers vertically */
    text-decoration: none;
    color: black;
    font-size: 1.1rem;

}

.arrow {
    margin-left: 5px;
    height: 15px;
}

.noStyleLink {
    text-decoration: none;
    color: inherit;
}


.hamburgerButton {
  background: none;
  border: none;
  color: black;
  font-size: 1.8rem;
  cursor: pointer;
  margin-right: 0px;
  padding-right: 20px;
}

/* Mobile Menu Styles */
.mobileNav {
    position: absolute;
    top: 100%; /* Aligns below the header */
    right: 10px; /* Adjusts to the right */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 0px;
    z-index: 1001;
    flex-direction: column;
    gap: 0px;
    width: max-content; /* Adjust width for better appearance */
}

/* General styling for all links in the mobile menu */
.mobileNav .link,
.mobileNav .link2 {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 15px;
    font-size: 1rem; /* Increase font size for readability */
    transition: background-color 0.2s;
    background-color: white; /* Ensure consistent background */
    border: none;
    border-radius: 5px; /* Match the hover style */
}

/* Hover effect for all mobile menu items */
.mobileNav .link:hover,
.mobileNav .link2:hover {
    background-color: #f8f8f8;
    border-radius: 5px;
}

/* Ensure buttons are consistent in appearance */
.link2 {
    color: #333; /* Match text color */
    background-color: white; /* Same background */
    padding: 15px; /* Match padding */
    font-size: 1rem; /* Match font size */
    margin-right: 0; /* Remove margin for consistency */
    margin-top: 0; /* Align with other links */
    text-align: center; /* Center text */
    font-weight: normal; /* Match font weight */
}


.mobileNav .link .link2 i {
    margin-right: 0px;
    font-size: 1.5rem; /* Increase icon size */
}


/* Container that holds the entire header */
.mobile-header-container {
    width: 100%;
    margin: 0 auto; /* Centers the header */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px; /* Add some padding for responsiveness */
}

/* Logo styles */
.logoMobile {
    height: 55px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: auto;
}
